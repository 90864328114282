<template>
	<div>
		<el-row type="flex" justify="end">
			<div @click="collapse = !collapse" class="mr-2">
				<el-popover
					class="btn btn-micro"
					placement="top-start"
					width="100"
					trigger="hover"
					content="Filtrar Relato"
				>
					<i
						slot="reference"
						:class="collapse ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
					></i>
				</el-popover>
			</div>
		</el-row>
		<br />
		<vuestic-widget v-show="collapse">
			<div class="container">
				<div class="row pb-1">
					<div class="col">
						<div class="form-group">
							<input v-model="search.checklist" id="search-checklist" />
							<label class="control-label" for="search-checklist">
								Nome da Checklist</label
							>
						</div>
					</div>
					<div class="col">
						<div class="form-group">
							<input v-model="search.area" id="search-area" />
							<label class="control-label" for="search-area">
								Area da Checklist</label
							>
						</div>
					</div>

					<div class="col">
						<div class="form-group">
							<input v-model="search.pergunta" id="search-pergunta" />
							<label class="control-label" for="search-pergunta">
								Pergunta</label
							>
						</div>
					</div>
					<div class="col">
						<div class="form-group">
							<input id="search-responsavel" v-model="search.responsavel" />
							<label class="control-label" for="search-responsavel">
								Responsável</label
							>
						</div>
					</div>
					<div class="col-3 form-group">
						<el-date-picker
							format="dd/MM/yyyy"
							v-model="search.data"
							type="daterange"
							range-separator="|"
							start-placeholder="Data inicial"
							end-placeholder="Data final"
							value-format="dd/MM/yyyy"
						/>
					</div>
					<!-- <div class="col">
            <div class="form-group">
              <vuestic-date-picker
                id="date-picker-range"
                :config="{
                    mode: 'range',
                    dateFormat: 'd/m/Y',
                    }"
                v-model="search.dataCadastro"
              />
              <label
                class="control-label"
                for="date-picker-basic"
              >
                Data de cadastro
              </label>
            </div>
          </div> -->
				</div>

				<form>
					<el-row type="flex"
class="row-bg"
justify="end">
						<!-- <el-button
              class="ml-3"
              type="primary"
              icon="el-icon-search"
              @click="getRelatos()"
            >
              Filtrar
            </el-button> -->
						<exportar-checklist
							class="ml-3"
							:data="checklistsExport"
							:searchExport="getChecklistFromExport"
						/>
						<el-button
							class="ml-3"
							type="danger"
							icon="el-icon-close"
							@click="limparPesquisa"
						>
							Limpar
						</el-button>
					</el-row>
				</form>
			</div>
		</vuestic-widget>
		<br />
		<vuestic-widget>
			<div class="row justify-content-end">
				<button
					@click="$router.push({ name: 'cadastrar-checklist-supervisor' })"
					class="btn btn-primary btn-micro mr-2"
				>
					Cadastrar
				</button>
				<button
					@click="
						$router.push({
							name: 'lista-checklist-duplicate-by-empresa-user-supervisor'
						})
					"
					class="btn btn-info btn-micro mr-2"
				>
					Duplicar modelo
				</button>
			</div>
			<data-tables-server
				v-loading="loading"
				:pagination-props="{ pageSizes: [10, 20, 50, 100] }"
				:total="count"
				style="width: 100%;"
				:data="checklists"
				:action-col="actionCol"
				:table-props="{ stripe: true }"
				@row-dblclick="pushResposta"
			>
				<el-table-column label="Checklist"
sortable="custom"
prop="nome" />
			</data-tables-server>
		</vuestic-widget>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import exportarChecklist from '../../my-components/export/checklist.vue';
import notificationMixin from '../../../utils/notification';

export default {
  components: { exportarChecklist },
  mixins: [notificationMixin],
  data() {
    return {
      collapse: false,
      loading: true,
      checklists: [],
      checklistsExport: [],
      count: null,
      idEmpresa: null,
      search: {
        checklist: '',
        area: '',
        pergunta: '',
        data: null,
        responsavel: '',
      },
      searchAux: {
        checklist: '',
        area: '',
        pergunta: '',
        responsavel: '',
      },
      actionCol: {
        label: 'Editar Checklist',
        props: {
          align: 'center',
        },
        buttons: [
          {
            props: {
              icon: 'el-icon-edit',
              type: 'primary',
              circle: true,
            },
            handler: (row) => {
              this.$router.push({
                name: 'cadastrar-checklist-id-supervisor',
                params: { checklist: row.id },
              });
            },
          },
          {
            props: {
              icon: 'el-icon-key',
              type: 'warning',
              circle: true,
            },
            handler: (row) => {
              this.$router.push({
                name: 'cadastrar-acesso-checklist-supervisor',
                params: { checklist: row.id },
              });
            },
          },
        ],
      },
    };
  },
  created() {
    this.idEmpresa = localStorage.empresaSelecionada;
    this.getChecklist();
  },
  methods: {
    ...mapActions('checklist', ['getChecklistFull', 'getChecklistPorAcesso']),
    pushResposta(checklist) {
      this.$router.push({
        name: 'lista-checklist-resposta-user-supervisor',
        params: { checklist: checklist.id },
      });
    },
    async getChecklist() {
      try {
        this.loading = true;
        const res = await this.getChecklistFull({
          params: { empresa: this.idEmpresa, 'sem-resposta': '' },
        });
        this.checklists = [...res];
        this.checklistsExport = [...res];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getChecklistFromExport() {
      try {
        if (!this.objectIsEqual(this.search, this.searchAux)) {
          let date = {};
          if (this.search.data != null) {
            const { data } = this.search;
            date = {
              tipo: 'cadastro',
              min_date: data[0],
              max_date: data[1],
            };
          }
          this.checklistsExport = await this.getChecklistPorAcesso({
            id: this.idEmpresa,
            params: {
              checklist_nome: this.search.checklist,
              area: this.search.area,
              pergunta_nome: this.search.pergunta,
              responsavel_resposta_nome: this.search.responsavel,
              ...date,
            },
          });
          this.searchAux = { ...this.search };
        }
      } catch (error) {
        console.error(error);
      }
    },
    objectIsEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys1) {
        if (object1[key] !== object2[key]) {
          return false;
        }
      }
      return true;
    },
    limparPesquisa() {
      this.search = {
        checklist: '',
        area: '',
        pergunta: '',
        data: null,
        responsavel: '',
      };
    },
  },
};
</script>

<style>
.style-icon-img {
	font-size: 1.3rem;
	color: #4e7db7;
}
.form-group .input-icon {
	position: absolute;
	top: 0.8rem;
	right: 2px;
	color: #b3b3b3;
}
</style>
